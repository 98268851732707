<template>
  <div>
    <div class="main-title">头像框设置</div>
    <div class="content-list-page page-content">
      <!--      <div class="list-head-box">-->
      <!--      <div class="list-head" data-v-69874ad0="">-->
      <!--          <p class="list-head-title" data-v-69874ad0="">温馨提示</p>-->
      <!--          <ul data-v-69874ad0="">-->
      <!--            <li data-v-69874ad0="">-->
      <!--              课程路径为：packageProduct/course/detail?id=-->
      <!--            </li>-->
      <!--            <li data-v-69874ad0="">-->
      <!--              组合购路径为：packageProduct/activity/promotion/detail?id=-->
      <!--            </li>-->
      <!--            <li data-v-69874ad0="">-->
      <!--              直播试听路径为：pages/promotionPage/trialCourse?id=-->
      <!--            </li>-->
      <!--          </ul>-->
      <!--      </div>-->
      <!--      </div>-->
      <div class="list-head-box"></div>

      <div class="padding-t-sm padding-b-sm">
        <span class="margin-l-sm fl-l margin-t-sm">
          <el-input
            v-model="searchData.level_name"
            clearable
            placeholder="请选择等级名称"
            size="mini"
          />
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="fl-r">
          <el-button
            size="small"
            type="primary"
            @click="$router.push('avatarFrame/add')"
            >新增</el-button
          >
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          highlight-current-row
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80" />
          <el-table-column label="等级名称" property="level_name" />
          <el-table-column label="最小打卡次数" property="min_sign_count" />
          <el-table-column label="最大打卡次数" property="max_sign_count" />

          <el-table-column label="头像框" property="address">
            <template slot-scope="scope">
              <el-image
                :src="imageUrl + scope.row.avatar_frame"
                style="width: 100px; height: 100px"
              />
            </template>
          </el-table-column>

          <el-table-column label="创建时间" property="created_at" />
          <el-table-column label="更新时间" property="updated_at" />

          <el-table-column label="操作" property="address">
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="$router.push('/avatarFrame/edit/' + scope.row.id)"
                >编辑
              </el-link>
              -

              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="advertisingDel(scope.row.id)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "@/js/config";

export default {
  name: "List",
  data() {
    return {
      searchData: {},
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      imageUrl: config.imageUrl,
    };
  },
  methods: {
    ...mapActions("avatarFrame", [
      "levelAvatarFrameList",
      "levelAvatarFrameDel",
    ]),

    search() {
      this.page = 1;
      this.getList();
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
    },
    async advertisingDel(id) {
      try {
        const { res_info } = await this.levelAvatarFrameDel({ id });
        if (res_info !== "ok") return;
        this.$message.success("删除成功");
        await this.getList();
      } catch (e) {
        console.log(e);
      }
    },
    setPage(page) {
      this.page = page;
    },
    async getList() {
      try {
        this.loading = true;
        let form = {
          page: this.page,
          pageSize: this.pageSize,
          ...this.searchData,
        };
        const { data } = await this.levelAvatarFrameList(form);
        this.tableData = data.list;
        this.total = data.total;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>